import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const AboutUs = ({ doctors }: any) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSeeMore = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const CircleImage = styled("img")`
    width: 100px;
    height: 100px;
    border-radius: 50%;
  `;

  const StyledButton = styled(Button)({
    color: "#f05627",
    "&.MuiButton-outlined": {
      borderColor: "#f05627",
    },
    transition: "color 0.3s",
    "&:hover": {
      color: "#f1925d",
    },
  });


  const CloseButton = styled(Button)({
    color: "#f05627",
    position: "absolute",
    top: 2,
    right: 2,
    "&.MuiButton-outlined": {
      borderColor: "#f05627",
    },
    transition: "color 0.3s",
    "&:hover": {
      color: "#f1925d",
    },
  });  

  

  const ModalContent = styled(Paper)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  `;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        About Us
      </Typography>
      {doctors.map((doctor: any, index: number) => (
        <Card
          key={index}
          sx={{ margin: "0 1rem 1rem 1rem", paddingBottom: "1rem" }}
        >
          <CardContent>
            <Typography variant="h6">{doctor.name}</Typography>
            <Typography variant="body2">{doctor.description}</Typography>
            <CircleImage src={doctor.image} alt={doctor.name} sx={{margin: "1rem 0"}}/>
          </CardContent>
          <StyledButton
            variant="outlined"
            sx={{ margin: "1rem 0" }}
            color="warning"
            onClick={handleSeeMore}
          >
            See More
          </StyledButton>
        </Card>
      ))}
      <Modal open={openModal} onClose={handleCloseModal}>
        <ModalContent>
          <CloseButton
            color="warning"
            variant="outlined"
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </CloseButton>
          <Typography variant="h5">Doctor Details</Typography>
          {/* Add additional information here */}
        </ModalContent>
      </Modal>
    </Box>
  );
};
