import React, { useEffect, useState } from 'react';
import './App.css';
import DetailsPage from './pages/SingleView/SingleView';
import SearchResultsPage from './pages/SearchResultsPage/SearchResultsPage';
// import LoginPage from './pages/LoginPage/LoginPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import ProfilePage from './pages/Settings/profile/ProfilePage';
import ReferalPage from './pages/Settings/referral/ReferalPage';
// import VideoUploadPage from './pages/VideoUploadPage';
import VideoEditPage from './pages/EditVideoPage/EditVideoPage'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

const client = new ApolloClient({
  uri: 'http://localhost:4000/graphql',
  cache: new InMemoryCache(),
});

function App(): JSX.Element {
  // const [user, setUser] = useState<any>(null);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const storedUser = localStorage.getItem('user');
  //   if (storedUser) {
  //     setUser(JSON.parse(storedUser));
  //   }
  //   setLoading(false);
  // }, []);

  // const handleLogin = (loggedInUser: any) => {
  //   setUser(loggedInUser);
  //   localStorage.setItem('user', JSON.stringify(loggedInUser));
  // };F
  const user = true;

  const handleLogout = () => {
    // setUser(null);
    localStorage.removeItem('user');
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/login" element={<LoginPage onLogin={handleLogin} />} /> */}
          {/* <Route
            path="/"
            element={user ? <DashboardPage handleLogout={handleLogout} storedUser={user}/> : <Navigate to="/login" replace />}
          /> */}
          <Route
            path="/"
            element={user ? <SearchResultsPage handleLogout={handleLogout} storedUser={user}/> : <Navigate to="/login" replace />}
          />
          <Route
            path="/details"
            element={user ? <DetailsPage handleLogout={handleLogout} storedUser={user} /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/search-results"
            element={
              user ? <SearchResultsPage handleLogout={handleLogout} storedUser={user} /> : <Navigate to="/login" replace />
            }
          />
          {/* <Route
            path="/video-upload"
            element={
              user ? <VideoUploadPage handleLogout={handleLogout} storedUser={user} /> : <Navigate to="/login" replace />
            }
          />           */}
          <Route
            path="/user-profile"
            element={
              user ? <ProfilePage handleLogout={handleLogout} storedUser={user} /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/referral-contact-us"
            element={
              user ? <ReferalPage handleLogout={handleLogout} storedUser={user} /> : <Navigate to="/login" replace />
            }
          /> 
          <Route
            path="/video-edit"
            element={
              user ? <VideoEditPage handleLogout={handleLogout} storedUser={user} /> : <Navigate to="/video-edit" replace />
            }
          />                    
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
