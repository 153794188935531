import React, { useState, ReactNode } from 'react';
import "./TruncateJSX.scss";
import { Button, styled } from '@mui/material';

type Props = {
  content: ReactNode;
  start?: number;
};

const StyledButton = styled(Button)({
  color: '#f05627',
  '&.MuiButton-outlined': {
    borderColor: '#f05627', 
  },  
  transition: 'color 0.3s',
  '&:hover': {
    color: '#f1925d',
  },
});

const TruncateJSX: React.FC<Props> = ({ content, start = 2 }) => {
  const [showMore, setShowMore] = useState(false);

  // Split the content into visible and hidden content
  const visibleContent = React.isValidElement(content)
    ? React.Children.toArray(content.props.children).slice(0, start)
    : undefined;
  const hiddenContent = React.isValidElement(content)
    // @ts-ignore
    ? React.Children.toArray(content.props.children).slice(start)
    : undefined;

  // Count non-empty tags to determine the actual start index
  let startIndex = 0;
  let nonEmptyCount = 0;
  React.Children.forEach(visibleContent, (child, index) => {
    if (React.isValidElement(child) && React.Children.count(child.props.children) > 0) {
      nonEmptyCount++;
    }
    if (nonEmptyCount >= start) {
      startIndex = index + 1;
      return false;
    }
  });
  

  return (
    <div>
      {/* Render the visible content */}
      {visibleContent && visibleContent.slice(0, startIndex)}

      {/* Render the hidden content if "showMore" is true */}
      {showMore && hiddenContent}

      {/* Render the "See more" button if there is hidden content */}
      {React.isValidElement(content) &&
        // @ts-ignore
        React.Children.count(content.props.children) > startIndex && (
          <StyledButton onClick={() => setShowMore(!showMore)} className='truncate-button' variant="outlined" sx={{margin: '1rem 0'}} color='warning'>
            {showMore ? 'See less' : 'See more'}
          </StyledButton>
        )}
    </div>
  );
};

export default TruncateJSX;
