// @ts-nocheck
import React, { useState } from "react";
import MainContainer from "../../containers/MainContainer";
import { Button, TextField, Box } from "@mui/material";
import FullFeaturedCrudGrid from "../../components/DataTableEditComponent/DataTableEditComponent";

// Definición de interfaces
interface MainVideo {
  title: string;
  description: string;
}

interface Attachment {
  title: string;
  document: File | null;
  [key: string]: string | File | null;
}

interface Link {
  title: string;
  url: string;
  [key: string]: string;
}

interface Thumbnail {
  title: string;
  time: string;
  image: File | null;
  [key: string]: string | File | null;
}

// Datos de ejemplo
const initialMainVideo: MainVideo = {
  title: "Sample Video",
  description: "This is a sample video description",
};

const initialAttachments: Attachment[] = [
  {
    title: "Attachment 1",
    document: null,
  },
  {
    title: "Attachment 2",
    document: null,
  },
];

const initialLinks: Link[] = [
  {
    title: "Link 1",
    url: "https://example.com",
  },
  {
    title: "Link 2",
    url: "https://example2.com",
  },
];

const initialThumbnails: Thumbnail[] = [
  {
    title: "Thumbnail 1",
    time: "0:00",
    image: null,
  },
  {
    title: "Thumbnail 2",
    time: "1:00",
    image: null,
  },
];

const EditPage = ({ handleLogout, storedUser }: any) => {
  const [mainVideo, setMainVideo] = useState<MainVideo>(initialMainVideo);
  const [attachments, setAttachments] =
    useState<Attachment[]>(initialAttachments);
  const [links, setLinks] = useState<Link[]>(initialLinks);
  const [thumbnails, setThumbnails] = useState<Thumbnail[]>(initialThumbnails);

  // Funciones para actualizar datos

  const handleMainVideoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setMainVideo({ ...mainVideo, [name]: value });
  };

  return (
    <MainContainer handleLogout={handleLogout} storedUser={storedUser}>
      <Box sx={{ paddingTop: "6vh", height: "100%", pb: 2 }}>
        {/* Main Video */}
        <Box sx={{ margin: "1vh 0vh" }}>
          <TextField
            label="Main Video Title"
            name="title"
            value={mainVideo.title}
            onChange={handleMainVideoChange}
          />
          <TextField
            label="Main Video Description"
            name="description"
            value={mainVideo.description}
            onChange={handleMainVideoChange}
            multiline
            rows={4}
            sx={{ marginLeft: 2, width: "30vw" }}
          />
        </Box>
        {/* Save button */}
        <Button variant="contained" color="primary">
          Save
        </Button>
        {/* Attachments */}
        <h2>Attachments</h2>
        <div style={{ width: "100%" }}>
          <FullFeaturedCrudGrid />
        </div>

        {/* Links */}
        <h2>Links</h2>
        <div style={{ width: "100%" }}>
          <FullFeaturedCrudGrid />
        </div>

        {/* Thumbnails */}
        <h2>Thumbnails</h2>
        <div style={{ width: "100%" }}>
          <FullFeaturedCrudGrid />
        </div>
      </Box>
    </MainContainer>
  );
};

export default EditPage;
