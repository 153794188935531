import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  styled,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { SEND_FORM_MUTATION } from "../../../../../graphql/mutations/mutations";

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 2rem;
`;

const RowContainer = styled(Box)`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)({
  background: "#f05627",
  "&.MuiButton-outlined": {
    borderColor: "#f05627",
  },
  transition: "background 0.3s",
  "&:hover": {
    background: "#f1925d",
  },
});

const PhoneLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding-bottom: 2rem;
  &:hover {
    color: #f05627;
  }
`;

export const ReferalForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    clinic: "",
    requestInfo: "",
  });

  const [sendForm, { loading, error, data }] = useMutation(SEND_FORM_MUTATION);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendForm({
      variables: {
        ...formData,
      },
    });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Referral / Contact Us
      </Typography>

      <PhoneLink variant="body1" href="tel:1234567890">
        Phone: 123-456-7890
      </PhoneLink>

      <FormContainer>
        <RowContainer>
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            autoComplete="off"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            autoComplete="off"
            sx={{ flexGrow: 2 }}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            autoComplete="off"
            sx={{ flexGrow: 2 }}
          />
        </RowContainer>
        <RowContainer>
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="off"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            autoComplete="off"
            sx={{ flexGrow: 1 }}
          />
        </RowContainer>
        <TextField
          label="Clinic"
          name="clinic"
          value={formData.clinic}
          onChange={handleChange}
          autoComplete="off"
          sx={{ flexGrow: 1 }}
        />
        <TextField
          placeholder="Request Information"
          name="requestInfo"
          value={formData.requestInfo}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ flexGrow: 1 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Send
        </StyledButton>
      </FormContainer>
      {loading && <p>Sending form...</p>}
      {error && <p>Error sending form.</p>}
      {data && <p>Form sent successfully.</p>}
    </Box>
  );
};
