import { forwardRef, useCallback, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Switch,
  Unstable_Grid2 as Grid,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { TransitionProps } from "@mui/material/transitions";

import Slide from "@mui/material/Slide";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_MUTATION } from "../../../../../graphql/mutations/mutations";

export const AccountProfileDetails = ({ storedUser }: any) => {
  const [values, setValues] = useState({
    firstName: storedUser.props.first_name,
    lastName: storedUser.props.last_name,
    email: storedUser.props.email,
    surgeon: storedUser.props.surgeon,
    type: storedUser.props.type,
    perms: { admin: storedUser.props.perms.admin },
  });

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const DialogBody = () => (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Change password"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Create your new password and confirm.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="new-password"
          label="New password"
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="confirmed-password"
          label="Confirm new password"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="error">
          Cancel
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );

  const DialogBodyConfirm = () => (
    <Dialog
      open={openConfirm}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseConfirm}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Confirmación"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to save the changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirm} variant="outlined" color="error">
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            handleSubmit(e);
            handleCloseConfirm();
          }}
          variant="outlined"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleChange = useCallback(
    (event: { target: { name: any; value: any } }) => {
      setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    },
    []
  );

  const [updateUser] = useMutation(UPDATE_USER_MUTATION);

  const handleSubmit = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();

      // Validate the fields before the update.
      if (!values.firstName || !values.lastName || !values.email) {
        console.error("Please complete all required fields.");
        return;
      }

      // Prepare the input object for the mutation
      const input = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        surgeon: values.surgeon,
        type: values.type, // Add all the fields you want to update
        perms: {
          admin: values.perms.admin, // If you want to update nested fields
          // Add other nested fields if necessary
        },
        // password: values.password, // Add the password field if necessary
      };
      console.log("HERE")
      try {
        const { data } = await updateUser({
          variables: {
            input,
          },
        });
  
       console.log(data)
      } catch (error) {
        console.error("Request failed:", error);
        // setError("An error occurred while logging in");
      }
      // Open the confirmation dialog
      // handleClickOpen();
    },
    [updateUser, values.email, values.firstName, values.lastName, values.perms.admin, values.surgeon, values.type]
  );

  const [checkedSurgeon, setCheckedSurgeon] = useState(true);
  const [checkedPerms, setCheckedPerms] = useState(true);

  const handleChangeSurgeon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedSurgeon(event.target.checked);
    handleChange(event);
  };

  const handleChangePerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedPerms(event.target.checked);
    handleChange(event);
  };

  return (
    <>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardHeader
            subheader="The information can be edited"
            title="Profile"
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: 2 }}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    helperText="Please specify the first name"
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={values.firstName}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={values.lastName}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    required
                    value={values.email}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={handleChangeSurgeon}
                          checked={checkedSurgeon}
                          inputProps={{ "aria-label": "controlled" }}
                          color="warning"
                        />
                      }
                      label="Surgeon"
                      name="surgeon"
                    />
                  </FormGroup>
                </Grid>
                {/* <Grid xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Type"
                    name="type"
                    onChange={handleChange}
                    required
                    value={values.type}
                  />
                </Grid> */}
                <Grid xs={12} md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={handleChangePerms}
                          checked={checkedPerms}
                          inputProps={{ "aria-label": "controlled" }}
                          color="success"
                        />
                      }
                      label="Permissions"
                      name="perms"
                    />
                  </FormGroup>
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    required
                    value={"********"}
                  />
                </Grid>
                <Grid
                  xs={6}
                  md={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClickOpen}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button variant="contained" type="submit" 
            // onClick={handleClickOpenConfirm}
            >
              Save details
            </Button>
          </CardActions>
        </Card>
      </form>
      <DialogBody />
      <DialogBodyConfirm />
    </>
  );
};
