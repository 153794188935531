import { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { timeFormat, timeToSeconds } from "./helpers/timeFormat";
import { styled, Tooltip, TooltipProps, tooltipClasses, Grid } from "@mui/material";

const image1 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/1-pre-op-x-ray-000004.png'
const image2 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/2-synovitis-000026.png'
const image3 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/3-torn-acl-000030.png'
const image4 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/4-arthroscopy-000038.png'
const image5 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/5-incision-tplo-001015.png'
const image6 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/6-radial-cut-tplo-saw-001321.png'
const image7 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/7-marking-proximal-boarder-001522.png'
const image8 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/8-distal-border-10mm-mark-001545.png'
const image9 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/9-osteotomy-001607.png'
const image10 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/10-pin-proximal-fragment-001707.png'
const image11 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/11-k-wire-tinial-tuberosity-002101.png'
const image12 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/12-plate-contoured-to-proximal-t-002244.png'
const image13 = 'https://spry.colibrimediagroup.com/vids/event-4-pike-arythroscopy-tplo/thumbnails/13-suturing-003103.png'

const image1_1 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/1-x-ray-000010.png'
const image2_2 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/2-incision-000025.png'
const image3_3 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/3-measure-plate-location-000647.png'
const image4_4 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/4-marking-the-bone-cut-000742.png'
const image5_5 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/5-cutting-the-bone-000852.png'
const image6_6 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/6-inserting-plate-001208.png'
const image7_7 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/7-injections-002553.png'
const image8_8 = 'https://spry.colibrimediagroup.com/vids/event-6-clementine-paul/thumbnails/8-suturing-002659.png'


const itemDataZero: any = [
  {
    img: image1,
    title: "Pre-Op X-Ray",
    mark: 4,
  },
  {
    img: image2,
    title: "Synovitis",
    mark: 26,
  },
  {
    img: image3,
    title: "Torn ACL",
    mark: 30,
  },
  {
    img: image4,
    title: "Arthroscopy",
    mark: 38,
  },
  {
    img: image5,
    title: "Incision TPLO",
    mark: 615,
  },
  {
    img: image6,
    title: "Radial Cut - TPLO Saw",
    mark: 891,
  },
  {
    img: image7,
    title: "Marking Proximal Boarder",
    mark: 922,
  },
  {
    img: image8,
    title: "Distal Border 10mm Mark",
    mark: 945,
  },
  {
    img: image9,
    title: "Osteotomy",
    mark: 967,
  },
  {
    img: image10,
    title: " Pin Proximal Fragment",
    mark: 1027,
  },
  {
    img: image11,
    title: "K-wire Tinial Tuberosity",
    mark: 1261,
  },
  {
    img: image12,
    title: "Plate contoured to proximal T",
    mark: 1364,
  },
  {
    img: image13,
    title: "Suturing",
    mark: 1863,
  },
];


const itemDataOne: any = [
  {
    img: image1_1,
    title: "X-Ray",
    mark: 10,
  },
  {
    img: image2_2,
    title: "Incision",
    mark: 25,
  },
  {
    img: image3_3,
    title: "Measure Plate Location",
    mark: 407,
  },
  {
    img: image4_4,
    title: "Marking the Bone",
    mark: 462,
  },
  {
    img: image5_5,
    title: "Cutting the Bone",
    mark: 532,
  },
  {
    img: image6_6,
    title: "Inserting Plate",
    mark: 728,
  },
  {
    img: image7_7,
    title: "Injections",
    mark: 1553,
  },
  {
    img: image8_8,
    title: "Suturing",
    mark: 1619,
  },

];

export default function MarkersComponent({ event }: any) {
  const queryParameters = new URLSearchParams(window.location.search);
  const idParam = queryParameters.get("id") || "";
  const [data, setData] = useState(itemDataZero);

  useEffect(()=>{
    if(idParam === "0") {
      setData(itemDataZero)
    }else{
      setData(itemDataOne)
    } 
  },[])

  const imgHover = {
    maxHeight: "150px",
    "&:hover": {
      cursor: "pointer",
    },
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const AnimatedImageListItem = styled(ImageListItem)({
    position: 'relative',
    overflow: 'hidden',
  });

  const AnimatedImage = styled('img')({
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  });

  // const StyledScrollGrid = styled(Grid)(({ theme }) => ({
  //   overflow: 'auto',
  //   scrollbarWidth: 'thin',
  //   scrollbarColor: `#fef4e8 #f05627`,

  //   '&::-webkit-scrollbar': {
  //     width: '8px',
  //   },

  //   '&::-webkit-scrollbar-track': {
  //     backgroundColor: '#fef4e8',
  //   },

  //   '&::-webkit-scrollbar-thumb': {
  //     background: `linear-gradient(to top, #f09661, #f05829)`,
  //     borderRadius: '2px',
  //   },
  // }));

  return (
    <Grid>
      <ImageList
        cols={5}
        sx={{ width: "100%", overflow: "auto" }}
      >
        {data.map((item:any) => (
          <BootstrapTooltip title={item.title + ' ' + timeFormat(item.mark)} placement="bottom">
            <AnimatedImageListItem key={item.img} sx={imgHover}>
              <AnimatedImage
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                onClick={() => event(item.mark)}
                loading="lazy" 
              />

              <ImageListItemBar
                title={item.title}
                subtitle={timeFormat(item.mark)}
                // sx={{width: "150px"}}
              />
              {/* <h1>{timeToSeconds("00:26:59")}</h1> */}
            </AnimatedImageListItem>
          </BootstrapTooltip>
        ))}
      </ImageList>
    </Grid>
  );
}
