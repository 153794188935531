import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      success
      user {
        id
        name
        username
        props {
          type
          email
          perms {
            admin
          }
          title
          surgeon
          last_name
          first_name
        }
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      user {
        id
        name
        username
        props {
          type
          email
          perms {
            admin
          }
          title
          surgeon
          last_name
          first_name
        }
      }
    }
  }
`;

export const CREATE_VIDEO_MUTATION = gql`
  mutation CreateVideo($input: VideoInput!) {
    createVideo(input: $input) {
      userId
      title
      description
      attachments {
        userId
        title
        document
      }
      links {
        userId
        title
        url
      }
      thumbnails {
        userId
        time
        image
      }
      mainVideo {
        ... on VideoMainVideo {
          path
        }
      }
    }
  }
`;

export const SEND_FORM_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      success
      user {
        id
        name
        username
        props {
          type
          email
          perms {
            admin
          }
          title
          surgeon
          last_name
          first_name
        }
      }
    }
  }
`;