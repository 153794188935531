import React, { useState, useEffect, useRef } from "react";
import "./TruncateText.scss";
import { Button, styled } from "@mui/material";

interface Props {
  text: string;
  maxLines: number;
}

const StyledButton = styled(Button)({
  color: '#f05627',
  '&.MuiButton-outlined': {
    borderColor: '#f05627', 
  },  
  transition: 'color 0.3s',
  '&:hover': {
    color: '#f1925d',
  },
});

const TruncateText: React.FC<Props> = ({ text, maxLines }) => {
  const [truncatedText, setTruncatedText] = useState<string>("");
  const [showFullText, setShowFullText] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const lineHeight = parseFloat(getComputedStyle(container).lineHeight);
    const maxHeight = lineHeight * maxLines;
    const textLength = text.length;

    if (container.clientHeight < maxHeight) {
      setTruncatedText(text);
      return;
    }

    let truncatedLength = 0;
    for (let i = 0; i < textLength; i++) {
      const truncated = text.substr(0, i) + "...";
      container.textContent = truncated;
      if (container.clientHeight > maxHeight) break;
      truncatedLength = i;
    }

    setTruncatedText(text.substr(0, truncatedLength) + "...");
  }, [text, maxLines, truncatedText]);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <>
      <div ref={containerRef} style={{marginTop: '0.5rem'}}>{showFullText ? text : truncatedText}</div>
      <StyledButton onClick={toggleFullText} className='truncate-button' variant="outlined" sx={{margin: '1rem 0'}} color="warning">
        {showFullText ? "See Less" : "See More"}
      </StyledButton>
    </>
  );
};

export default TruncateText;
