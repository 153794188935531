import React, { useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Divider, List } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchContext } from "../../context/SearchContext/SearchContext";

const MainMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location?.pathname === "/";
  const isAvailable = location?.pathname === "/search-results";
  const { data } = useSearchContext();

  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleResetCatParam = () => {
    if (!data?.onSearch) {
      navigate("/search-results", { replace: true }); // Replace the current URL without adding it to the browser's history
    }
    return false;
  };

  const handleNavigate = (category: any) => {
    if (!data?.onSearch) {
      navigate(`/search-results?cat=${category}`);
    }
    return false;
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const mainListItems = (
    <React.Fragment>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" onClick={() => navigate("/")} />
      </ListItemButton>
    </React.Fragment>
  );

  const secondaryListItems = (
    <React.Fragment>
      {!isDashboard && isAvailable && (
        <>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Results" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleResetCatParam()}
              >
                <ListItemText
                  primary={`(${data?.resultsNumber}) All Results`}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleNavigate("symptons-procedures")}
              >
                <ListItemText
                  primary={`(${data?.resultsNumber}) Symptons / Procedures`}
                  sx={{ color: "#30af52" }}
                />
              </ListItemButton>
              {/* <ListItemButton sx={{ pl: 4 }} onClick={() => handleNavigate("professional-education")}>
                <ListItemText primary="(2) Professional Education" sx={{color: "#e75e33"}} />
              </ListItemButton> */}
            </List>
          </Collapse>
        </>
      )}
    </React.Fragment>
  );
  return (
    <List component="nav">
      {mainListItems}
      <Divider sx={{ my: 1 }} />
      {secondaryListItems}
    </List>
  );
};

export default MainMenu;
