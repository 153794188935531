import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";
import MarkersComponent from "../Markers/MarkersComponent";

class VideoPlayer extends Component {
  state = {
    // @ts-ignore
    url: this.props.vidUrl,
    pip: false,
    playing: false,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  };

  load = (url: any) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  handleClickFullscreen = () => {
    // @ts-ignore
    screenfull.request(findDOMNode(this.player));
  };

  handleMarkerChange = (mark:any) => {
    this.player.seekTo(mark)
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }

  player: any;
  ref = (player: any) => {
    this.player = player;
  };


  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      playbackRate,
      pip,
    } = this.state;

    return (
      <Box sx={{ paddingTop: "6vh" }}>
        <ReactPlayer
          ref={this.ref}
          className="react-player"
          width="100%"
          height="100%"
          url={url}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onError={(e) => console.log("onError", e)}
          onBuffer={() => console.log("Buffering")}
        />
        <MarkersComponent event={this.handleMarkerChange} />
      </Box>
    );
  }
}

export default VideoPlayer;
