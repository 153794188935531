import * as React from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  alpha,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MainMenu from "../../components/SideBarNav/SideBarNav";
import logo from "../../assets/logo.webp";

import { InputBase, Menu, MenuItem, Typography, Button } from "@mui/material";
import { useMemo, useState } from "react";
import { useSearchContext } from "../../context/SearchContext/SearchContext";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #000",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#FFF",
  color: "#000",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const CopyrightContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#FDF1EA",
  padding: theme.spacing(2),
  textAlign: "center",
  left: 0,
  bottom: 0,
  position: "fixed",
  width: "100%",
}));

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: center;
`;

const CopyrightImage = styled("img")`
  width: 10.5rem;
`;

const StyledSearchButton = styled(Button)({
  backgroundColor: "#f05627",
  color: "#FFF",
  "&.MuiButton-outlined": {
    borderColor: "#f05627",
  },
  transition: "backgroundColor 0.3s",
  "-webkit-transition": "backgroundColor 0.3s",
  "-o-transition": "backgroundColor 0.3s",
  "-moz-transition": "backgroundColor 0.3s",
  "&:hover": {
    backgroundColor: "#f1925d",
  },
});

const mdTheme = createTheme();

const MainContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAvailable = location?.pathname === "/details/";
  const { data: searchData, setData: setSearchData } = useSearchContext();
  const [searchText, setSearchText] = useState(searchData?.searchTerm);
  const isSearchPage = location.pathname === "/";
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const user = props.storedUser;
  const isContainer = props.container;

  // const menuId = "primary-search-account-menu";
  const renderMenu = (
    // <Menu
    //   anchorEl={anchorEl}
    //   anchorOrigin={{
    //     vertical: "top",
    //     horizontal: "right",
    //   }}
    //   id={menuId}
    //   keepMounted
    //   transformOrigin={{
    //     vertical: "top",
    //     horizontal: "right",
    //   }}
    //   open={isMenuOpen}
    //   onClose={handleMenuClose}
    // >
    //   <MenuItem disabled>Welcome {user.username.toUpperCase()}</MenuItem>
    //   <MenuItem onClick={() => navigate("/user-profile")}>Profile</MenuItem>
    //   {/* <MenuItem onClick={() => navigate("/video-upload")}>
    //     Upload a Video
    //   </MenuItem> */}
    //   <MenuItem onClick={props.handleLogout}>Logout</MenuItem>
    // </Menu>
    <></>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem disabled>Welcome {user.username.toUpperCase()}</MenuItem> */}
      <MenuItem onClick={() => navigate("/user-profile")}>Profile</MenuItem>
      {/* <MenuItem onClick={() => navigate("/video-upload")}>
        Upload a Video
      </MenuItem> */}
      <MenuItem onClick={props.handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const handleInputChange = useMemo(
    () => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setSearchText(event.target.value);
    },
    [setSearchText]
  );

  const handleSearch = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const navigateTo = "/search-results?search=" + searchText;
    setSearchData({ ...searchData, searchTerm: searchText });
    navigate(navigateTo);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} elevation={0}>
          <Toolbar
            sx={{
              pr: "44px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/">
              <Box component="img" sx={{ height: 54 }} alt="Logo" src={logo} />
            </Link>
            
              <>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleInputChange}
                    value={searchText}
                  />
                </Search>
                <StyledSearchButton
                  variant="contained"
                  onClick={(e) => handleSearch(e)}
                >
                  Search
                </StyledSearchButton>
              </>
            
            <Box sx={{ flexGrow: 1 }} />
            {/* {isAvailable && <StyledSearchButton
              onClick={() => navigate("/video-edit?id=")}
            >
              Edit
            </StyledSearchButton>} */}
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {/* <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={"primary-search-account-menu"}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton> */}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={"show-more-menu"}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <MainMenu />
        </Drawer>
        <Grid mb={2} />
        <Grid
          container
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            padding: "2vh 2vh 6vh 2vh;",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            mt={props?.marginTop}
            container={isContainer}
          >
            {props.children}
          </Grid>
        </Grid>
      </Box>
      <CopyrightContainer>
        <LogoContainer>
          <CopyrightImage src={logo} alt="Colibri Media Group" />
          <Typography variant="body2" color="textSecondary" pt={"0.3rem"}>
            &copy; 2023 All rights reserved
          </Typography>
        </LogoContainer>
      </CopyrightContainer>
    </ThemeProvider>
  );
};

export default MainContainer;
