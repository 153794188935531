import React from 'react';
import MainContainer from '../../../containers/MainContainer';
import { Box, Container, Grid, Stack } from '@mui/material';
import { AccountProfile } from './components/AccountProfile/AccountProfile';
import { AccountProfileDetails } from './components/AccountProfileDetails/AccountProfileDetails';

const ProfilePage = ({handleLogout, storedUser}:any) => {

  return (
    <MainContainer handleLogout={handleLogout} storedUser={storedUser}>
      <Box sx={{ textAlign: "center", paddingTop: "10vh" }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Grid container spacing={3}>
                  <Grid xs={12} md={6} lg={4}>
                    <AccountProfile storedUser={storedUser}/>
                  </Grid>
                  <Grid xs={12} md={6} lg={8}>
                    <AccountProfileDetails storedUser={storedUser}/>
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </Container>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default ProfilePage;