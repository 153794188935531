import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";


export const AccountProfile = ({storedUser}:any) => (
  <Card sx={{ marginRight: 2 }}>
    <CardContent>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Avatar
          src={'/assets/avatars/avatar-anika-visser.png'}
          sx={{
            height: 80,
            mb: 2,
            width: 80,
          }}
        />
        <Typography gutterBottom variant="h5">
          {storedUser.name}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {storedUser.props.email}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    {/* <CardActions>
      <Button fullWidth variant="outlined" component="label">
        Upload Picture
        <input type="file" hidden />
      </Button>
    </CardActions> */}
  </Card>
);
