import moment from 'moment';

export const timeFormat = (secs:any) => {
    const formatted = moment.utc(secs*1000).format('HH:mm:ss');

    return formatted;
}

export const timeToSeconds = (timeString: any) => {
    const duration = moment.duration(timeString);
    const seconds = duration.asSeconds();
    
    return seconds;
}
