import React, { ReactNode, createContext, useContext, useState } from 'react';

// Define el tipo para el contexto
interface MyContextType {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

// Crea el contexto
const MyContext = createContext<MyContextType | undefined>(undefined);

// Exporta un componente proveedor que envuelve a tus componentes principales
export const SearchContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<string | null>(null);

  return (
    <MyContext.Provider value={{ data, setData }}>
      {children}
    </MyContext.Provider>
  );
};

// Crea un hook personalizado para acceder al contexto
export const useSearchContext = (): MyContextType => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useSearchContext debe ser utilizado dentro de un SearchContextProvider');
  }
  return context;
};
