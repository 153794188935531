import React from 'react';
import MainContainer from '../../../containers/MainContainer';
import { Box, Container, Grid, Stack } from '@mui/material';
import { AboutUs } from './components/AboutUs/AboutUs';
import { ReferalForm } from './components/ReferalForm/ReferalForm';

const ReferalPage = ({handleLogout, storedUser}:any) => {

  const doctors = [
    {
      name: 'Dr. John Doe',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: 'https://example.com/doctor1.jpg',
    },
    {
      name: 'Dr. Jane Smith',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: 'https://example.com/doctor2.jpg',
    },
  ];

  return (
    <MainContainer handleLogout={handleLogout} storedUser={storedUser}>
      <Box sx={{ textAlign: "center", paddingTop: "10vh" }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Grid container spacing={3}>
                  <Grid xs={12} md={6} lg={6}>
                    <AboutUs doctors={doctors}/>
                  </Grid>
                  <Grid xs={12} md={6} lg={6}>
                    <ReferalForm/>
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </Container>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default ReferalPage;